<template>
    <div class="container-fluid">
        <div class="row ps-3 m-0 p-0">
            <div class="col-md-3 row p-0">
                <div class="col-md-3 p-0 pt-2">
                    <p class="fw-bold">lokasi :</p>
                </div>
                <div class="col-md-9 mb-0">
                    <select v-model="pages.dept" class="form-select mb-3" style="width: 100%;" required v-on:change="loadData($event)">
                        <option class="" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-9">
                <Toolbar
            :click-edit="showEdit"
            :click-un="showUn"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Approve.xls"
        />
            </div>
        </div>
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-215) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tbody>
                            <tr v-for="(dt, index) in recs" :key="dt.ta_so" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Nomer SO
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_so}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Customer</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_name}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Nominal</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_amt | toRp}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Tempo</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_top}}
                                    </div>
                                </td>
                                <td width="50px" class="text-center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    <span class="w3-hide-large" v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_so')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_so}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_IdCust')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.tc_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_date')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_date}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_outstandingSO')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_outstandingSO | toRp}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_amt')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_amt | toRp}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_creditLimit')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_creditLimit | toRp}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_top')" class="w3-hide-small w3-hide-medium text-center">
                                    {{dt.ta_top}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','changed_by')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.changed_by}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:' + findObjFind('width','ta_approved')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>
        <div id="modal-form" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content col-md-3">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-form').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Approved</p>
                    </div>
                    <div class="modal-body">
                        <form class="container" id="frmAprove" v-on:submit.prevent="saveData" autocomplete="off">
                            <div class="mb-3">
                                    <label for="ta_so" class="form-label h9 fw-bold">{{findObjFind('name','ta_so')}} :</label>
                                    <input ref="ta_so" readonly type="text" class="form-control" id="ta_so" :placeholder="findObjFind('name','ta_so')" v-model="rec.ta_so">
                                    <input ref="ta_sys" readonly type="text" hidden class="form-control" id="ta_sys" :placeholder="findObjFind('name','ta_sys')" v-model="rec.ta_sys">
                            </div>
                            <div class="mb-3">
                                    <label for="ta_reason" class="form-label h9 fw-bold">{{findObjFind('name','ta_reason')}} :</label>
                                    <input ref="ta_reason" required type="text" class="form-control" id="ta_reason" :placeholder="findObjFind('name','ta_reason')" v-model="rec.ta_reason">
                                    <input ref="changed_by" hidden required type="text" class="form-control" id="changed_by" :placeholder="findObjFind('name','ta_reason')" v-model="username">
                            </div>
                        </form>
                        <p class="fw-bold h9">
                            Daftar barang
                        </p>
                        <table class="table table-hover table-responsive" id="barang" style="cursor:pointer">
                            <thead>
                                <tr class="table-head">
                                    <th class="">No</th>
                                    <th class="">Nama Barang</th>
                                    <th class="">Qty</th>
                                    <th class="">Harga</th>
                                    <th class="">Total</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="modal-footer"  style="margin-top: 15px;">
                        <h6 class="w3-right">
                        <button form="frmAprove" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                        <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </div>
                    </div>
            </div>
        </div>
        <div id="modal-un" class="modal row mt-5 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="w3-animate-zoom modal-content col-md-3">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-un').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Approved</p>
                    </div>
                    <div class="modal-body">
                        <form class="container mb-3" id="cekUn" v-on:submit.prevent="cekunUp" autocomplete="off">
                            <label for="ta_so" class="form-label h9 fw-bold">{{findObjFind('name','ta_so')}} :</label>
                                <div class="input-group">
                                    <input ref="ta_so" type="text" class="form-control" id="ta_so" required v-model="ren.ta_so">
                                    <button form="cekUn" class="input-group-text btn btn-warning" type="submit"> Cek</button>
                                </div>
                        </form>
                        <form action="" class="container d-none" id="dtDetail">
                            <div class="">
                                <p class="">Nomer SO : <b>{{rens.TrNo}}</b></p>
                                <p class="">Nama Customer : <b>{{rens.Custname}}</b></p>
                                <p class="">Status Invoice :  <b><i class="fa" :class="{'fa-check': rens.statusSi !== 1, 'fa-times': rens.statusSi === 1}"></i></b></p>
                                <p class="">Status Surat Jalan :  <b><i class="fa" :class="{'fa-check': rens.statusSj !== 1, 'fa-times': rens.statusSj === 1}"></i></b></p>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer"  style="margin-top: 15px;">
                        <h6 class="w3-right">
                        <button :disabled="rens.statusSj === 1" id="unapin" class="btn btn-warning rounded" type="submit" @click="unAp"><i class="fa fa-save"></i> Unapprove</button>
                        <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-un').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import { Crypton } from 'laravel-crypton';
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar2.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {

    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Approved',
    components: {
        Toolbar
    },
    data() {
        return {
            username : localStorage.username,
            userz : [],
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            dept: [
                { text: 'SURABAYA', value: '1' },
                { text: 'SEMARANG', value: '2' },
                { text: 'Semua', value: '3' },
            ],
            approvel:[
                {text: 'SA', value: 'admin'},
                {text: 'GM', value: 'jw'},

            ],
            sys : {sys : ''},
            rec: {},
            ren: {},
            rens: {},
            recs: [],
            recz: {},
            reci: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:'',dept : '3',},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'ta_so', type: 'text', name : 'Nomer SO', filter : true, width : '120px', grid : true},
                        {fieldFind : '', key : 'ta_IdCust', type: 'text', name : 'Customer', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'ta_date', type: 'text', name : 'Tanggal Pengajuan', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'ta_outstandingSO', type: 'text', name : 'Unpaid SO', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_amt', type: 'text', name : 'Nominal', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_creditLimit', type: 'text', name : 'Limit Kredit', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_top', type: 'text', name : 'Tempo', filter : true, width : '60px', grid : true},
                        {fieldFind : '', key : 'changed_by', type: 'text', name : 'User', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_approved', type: 'text', name : 'Status', filter : true, width : '35px', grid : true, valueFind: [{key:'1',label:'Approved'},{key:'0',label:'Non'}]},
                        {fieldFind : '', key : 'ta_reason', type: 'text', name : 'Keterangan', filter : true, width : '150px', grid : false},
                        {fieldFind : '', key : 'ta_sys', type: 'text', name : 'sys', filter : true, width : '150px', grid : false},
                ],
                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'SO' : 'ta_so',
                'NAMA CUSTOMER' : 'ta_IdCust',
                'TANGGAL PENGAJUAN' : 'ta_date',
                'OUT STANDING SO' : 'ta_outstandingSO',
                'NOMINAL' : 'ta_amt',
                'KREDIT LIMIT' : 'ta_creditLimit',
                'TEMPO' : 'ta_top',
                'PEMBUAT SO' : 'changed_by',
                'STATUS' : 'ta_approved',
                'ALASAN PENGAJUAN' : 'ta_reason'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.dept;
            self.rec = {};
            let params = {
                pfunction:'shows',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search,
                dept : self.pages.dept
            }
            axios.post("master/ApiApproved.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                self.pages.dept = self.pages.dept;
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        showEdit(){
            console.log(this.username);
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('Pilih Data');
                return false;
            }else{
                this.sys.sys = this.rec.ta_sys;
                this.stsAdd = false;
                let self = this;
            axios.post("master/ApiApproved.php",{
                pfunction : 'showitm',
                sys : self.sys.sys,

            }).then(function (response) {
                self.reci = response.data.recz;
                $('#barang').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.reci,
                    columns: [
                        {"data": "LineNo"},
                        {"data": "Description"},
                        {"data": "Qty"},
                        {"data": "UnitPrice"},
                        {"data": "NetAmt"}
                    ],
                    columnDefs: [
                    ],
                    dom:
                    "<'row'<'col-md-12 h9 mb-3'tr>>"
                });
            });
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('ta_so').focus();
                },500);
            }
        },
        showUn(){
            var self = this;
            self.ren={};
            self.rens = {};
            var dtDetail = document.getElementById('dtDetail');
            dtDetail.classList.add('d-none');
            document.getElementById('modal-un').style.display='block';
            var unapin = document.getElementById('unapin');
                    unapin.classList.add('d-none');

        },
        cekunUp(){
            var self = this;
            self.ren = {
                ta_so : self.ren.ta_so,
                pfunction : 'cekSo'
            };
            axios.post("master/ApiApproved.php",self.ren).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.rens = response.data.ren;
                    var dtDetail = document.getElementById('dtDetail');
                    dtDetail.classList.remove('d-none');
                    var unapin = document.getElementById('unapin');
                    unapin.classList.remove('d-none');
                }else{
                    self.$toast.error(response.data.msg);
                }
            });


        },
        unAp(){
            var self = this;
            console.log('Unapprove button clicked');
            axios.post("master/ApiApproved.php",self.rens).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-un').style.display='none';
                    var unapin = document.getElementById('unapin');
                    unapin.classList.remove('d-none');
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
        },
        saveData(event){
            var self = this;
            if (self.username === 'jw') {
                self.rec.pfunction = "editjw";
            }else{
                self.rec.pfunction = "edit";
            }
            axios.post("master/ApiApproved.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.username) {
                        self.recs[self.selectedRow] = self.rec;
                    }else{
                        self.recs[self.selectedRow] = self.rec;

                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm' ><span class='fa fa-check'></span></div>";
            } else {
                return "<div class='btn btn-danger btn-sm'><span class='fa fa-close'></span><div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiApproved.php",{
                pfunction : 'setapproved'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();
    }
};
</script>